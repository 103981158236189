<template>
  <v-dialog v-model="dialog" width="500">
    <v-card>
      <v-container>
        <v-card-title> Liitä tili {{ account.name }} ryhmään </v-card-title>

        <v-card-text class="mt-1">
          <v-form ref="form">
            <v-autocomplete
              v-model="groupId"
              :items="activeGroups"
              item-text="name"
              item-value="_id"
              label="Ryhmä"
              no-data-text="Ryhmän nimi tai *"
              outlined
              dense
              small-chips
              hide-details
              clearable
              no-filter
              @input.native="getGroups"
            >
            </v-autocomplete>
            <small>Ryhmän nimi tai *</small>
            <p :class="!groupId ? 'error--text' : 'success--text'" class="mt-1">
              {{
                !groupId
                  ? "Ryhmä poistetaan tililtä, jos tallennat"
                  : "Ryhmä liitetään tiliin, jos tallennat"
              }}
            </p>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-btn color="error" outlined @click="dialog = false">Poistu</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="info" @click="edit">Tallenna</v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import mixins from "@/mixins/mixins";
import { debounce } from "../../../utils/helpers";
import { mapState, mapActions, mapMutations } from "vuex";
import validations from "@/validations";
import globalValues from "../../../configs/globalValues";

export default {
  props: {
    value: { type: Boolean, default: false },
  },

  mixins: [mixins],

  data() {
    return {
      groupId: null,
      groupInput: "",
      validations,
      globalValues,
    };
  },

  computed: {
    ...mapState("group", ["activeGroups"]),
    ...mapState("account", ["account"]),

    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  watch: {
    account(val) {
      if (val && val.group?._id) {
        this.setToActiveGroups(val.group);
        this.groupId = val.group._id;
      } else {
        this.setToActiveGroups(null);
        this.groupId = null;
      }
    },

    groupInput: debounce(function (newVal) {
      this.searchGroups(`/api/v1/group/search?search=${newVal}`);
    }, 1000),

    value(val) {
      if (!val) this.groupId = null;
    },
  },

  methods: {
    ...mapActions("group", ["searchGroups"]),
    ...mapMutations("group", ["setToActiveGroups"]),
    ...mapActions("account", ["attachGroupToAccount"]),

    getGroups(event) {
      this.groupInput = event.target.value;
    },

    async edit() {
      try {
        await this.attachGroupToAccount({ groupId: this.groupId, accountId: this.account._id });
        if (this.groupId) {
          this.showPopup("Ryhmä liitetty tiliin", "success");
        } else {
          this.showPopup("Ryhmä poistettu tililtä", "success");
        }
        this.dialog = false;
      } catch (err) {
        this.showPopup(err, "error");
      }
    },
  },
};
</script>

<style scoped></style>
