<template>
  <div class="py-3 min-w-0 w-full">
    <div class="display-1 d-flex">Liitä tilit ryhmiin</div>

    <v-card class="mt-2">
      <v-card-title>
        <v-spacer></v-spacer>

        <v-btn
          v-if="isAuthorized('group', 'attach')"
          color="primary mr-1 mb-2 ml-auto"
          @click="openAttachMultipleDialog"
        >
          Liitä valitut
        </v-btn>
      </v-card-title>

      <v-container>
        <v-row dense>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="searchAccount"
              label="Tilin nimi"
              single-line
              hide-details
              append-icon="mdi-magnify"
              solo
              @keyup.enter="getDataFromApi()"
              @click:append="getDataFromApi()"
              @input="searchGroup = ''"
            ></v-text-field>

            <v-text-field
              v-model="searchGroup"
              class="mt-1"
              label="Ryhmän nimi"
              single-line
              hide-details
              append-icon="mdi-magnify"
              solo
              @keyup.enter="getDataFromApi()"
              @click:append="getDataFromApi()"
              @input="searchAccount = ''"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
      <v-data-table
        :headers="headers"
        :items="accounts"
        :footer-props="globalValues.footerProps"
        :items-per-page="getDefaultTableRows()"
        :loading="loading"
        :options.sync="options"
        :server-items-length="totalLength"
        v-model="selected"
        show-select
        item-key="_id"
      >
        <!-- name  -->
        <template #[`item.name`]="{ item }">
          <span class="font-weight-bold">{{ item.name }}</span>
        </template>

        <!-- group  -->
        <template #[`item.group`]="{ item }">
          <span v-if="item.group">{{ item.group.name }}</span>
        </template>

        <!-- Active -->
        <template #[`item.active`]="{ item }">
          <span :class="item.active ? 'success--text' : 'error--text'" class="font-weight-bold">{{
            item.active ? "Kyllä" : "Ei"
          }}</span>
        </template>

        <!-- Actions -->
        <template #[`item.actions`]="{ item }">
          <div class="action-grid-container">
            <v-icon
              v-if="isAuthorized('group', 'attach')"
              title="Muokkaa"
              small
              @click="editItem(item)"
              >mdi-pencil</v-icon
            >
          </div>
        </template>
        <template #no-data>
          <h2>Ei ryhmiä</h2>
        </template>
      </v-data-table>
    </v-card>

    <!-- Edit single account -->
    <attach-group v-model="groupDialog"></attach-group>

    <!-- Edit multiple accounts -->
    <attach-multiple-accounts
      v-model="attachMultipleDialog"
      @emptyselectedandfetch="emptyselectedAndFetch"
    ></attach-multiple-accounts>
  </div>
</template>

<script>
import mixins from "@/mixins/mixins";
import AttachGroup from "@/components/GroupComponents/Group/AttachGroup";
import AttachMultipleAccounts from "@/components/GroupComponents/Group/AttachMultipleAccounts";
import { mapActions, mapState, mapMutations } from "vuex";
import globalValues from "@/configs/globalValues";

export default {
  title: "Liitä tilit ryhmiin",

  mixins: [mixins],

  components: {
    AttachGroup,
    AttachMultipleAccounts,
  },

  data() {
    return {
      headers: [
        { text: "Nimi", value: "name" },
        { text: "Ryhmä", value: "group" },
        { text: "Aktiivinen", value: "active" },
        { text: "Toiminnot", value: "actions", sortable: false },
      ],
      selected: [],
      searchAccount: "",
      searchGroup: "",
      groupDialog: false,
      attachMultipleDialog: false,
      loading: true,
      options: {},
      globalValues,
    };
  },

  computed: {
    ...mapState("account", ["accounts", "totalLength"]),
    ...mapState("group", ["selectedAccounts"]),
    ...mapState("browsehistory", [
      "dataTablePage",
      "getPreviousPage",
      "itemsPerPage",
      "mainSearchValue",
    ]),
  },

  watch: {
    options: {
      handler() {
        if (this.getPreviousPage) {
          if (this.mainSearchValue != "") this.searchAccount = this.mainSearchValue;
          this.options.page = this.dataTablePage;
          this.options.itemsPerPage = this.itemsPerPage;
        }
        this.saveTableOptions({ ...this.options });
        this.getDataFromApi();
      },
      deep: true,
    },

    selected: {
      deep: true,
      handler(val) {
        const accounts = [];

        if (val.length > 50) {
          this.showPopup("Et voi valita enempää kuin 50 tiliä kerralla.", "error");
          return this.selected.pop();
        }

        val.forEach((item) => {
          accounts.push({
            _id: item._id,
            name: item.name,
          });
        });

        this.setSelectedAccounts(accounts);
      },
    },

    totalLength() {
      this.options.page = 1;
    },
  },

  methods: {
    ...mapActions("account", ["getAllAccounts"]),
    ...mapMutations("account", ["setAccount"]),
    ...mapMutations("browsehistory", ["saveTableOptions", "saveMainSearchTerm"]),
    ...mapMutations("group", ["setSelectedAccounts", "setToActiveGroups"]),

    async getDataFromApi() {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      // Save main search term
      this.saveMainSearchTerm(this.searchAccount);

      let searchAccount = this.searchAccount.trim().toLowerCase();
      let searchGroup = this.searchGroup.trim().toLowerCase();

      let url = `/api/v1/account/get-accounts?sortBy=${sortBy}&sortDesc=${sortDesc}&page=${page}&itemsPerPage=${itemsPerPage}&searchAccount=${searchAccount}&searchGroup=${searchGroup}`;

      await this.getAllAccounts(url);

      this.loading = false;
    },

    emptyselectedAndFetch() {
      this.selected = [];
      this.setSelectedAccounts([]);
      this.getDataFromApi();
    },

    openAttachMultipleDialog() {
      if (this.selectedAccounts.length > 0) {
        this.setToActiveGroups(null);
        this.attachMultipleDialog = true;
      } else {
        this.showPopup("Valitse ensin muokattavat tilit", "error");
      }
    },

    editItem(item) {
      this.selected = [];
      this.setSelectedAccounts([]);
      this.setAccount({ ...item });
      this.groupDialog = true;
    },
  },
};
</script>

<style scoped></style>
