var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"py-3 min-w-0 w-full"},[_c('div',{staticClass:"display-1 d-flex"},[_vm._v("Liitä tilit ryhmiin")]),_c('v-card',{staticClass:"mt-2"},[_c('v-card-title',[_c('v-spacer'),(_vm.isAuthorized('group', 'attach'))?_c('v-btn',{attrs:{"color":"primary mr-1 mb-2 ml-auto"},on:{"click":_vm.openAttachMultipleDialog}},[_vm._v(" Liitä valitut ")]):_vm._e()],1),_c('v-container',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":"Tilin nimi","single-line":"","hide-details":"","append-icon":"mdi-magnify","solo":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.getDataFromApi()},"click:append":function($event){return _vm.getDataFromApi()},"input":function($event){_vm.searchGroup = ''}},model:{value:(_vm.searchAccount),callback:function ($$v) {_vm.searchAccount=$$v},expression:"searchAccount"}}),_c('v-text-field',{staticClass:"mt-1",attrs:{"label":"Ryhmän nimi","single-line":"","hide-details":"","append-icon":"mdi-magnify","solo":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.getDataFromApi()},"click:append":function($event){return _vm.getDataFromApi()},"input":function($event){_vm.searchAccount = ''}},model:{value:(_vm.searchGroup),callback:function ($$v) {_vm.searchGroup=$$v},expression:"searchGroup"}})],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.accounts,"footer-props":_vm.globalValues.footerProps,"items-per-page":_vm.getDefaultTableRows(),"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.totalLength,"show-select":"","item-key":"_id"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.name))])]}},{key:"item.group",fn:function(ref){
var item = ref.item;
return [(item.group)?_c('span',[_vm._v(_vm._s(item.group.name))]):_vm._e()]}},{key:"item.active",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold",class:item.active ? 'success--text' : 'error--text'},[_vm._v(_vm._s(item.active ? "Kyllä" : "Ei"))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"action-grid-container"},[(_vm.isAuthorized('group', 'attach'))?_c('v-icon',{attrs:{"title":"Muokkaa","small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("mdi-pencil")]):_vm._e()],1)]}},{key:"no-data",fn:function(){return [_c('h2',[_vm._v("Ei ryhmiä")])]},proxy:true}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1),_c('attach-group',{model:{value:(_vm.groupDialog),callback:function ($$v) {_vm.groupDialog=$$v},expression:"groupDialog"}}),_c('attach-multiple-accounts',{on:{"emptyselectedandfetch":_vm.emptyselectedAndFetch},model:{value:(_vm.attachMultipleDialog),callback:function ($$v) {_vm.attachMultipleDialog=$$v},expression:"attachMultipleDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }