<template>
  <v-dialog v-model="dialog" width="500">
    <v-card>
      <v-container>
        <v-card-title> Liitä tilit ryhmään </v-card-title>

        <v-card-text class="mt-1">
          <v-form ref="form">
            <v-autocomplete
              v-model="groupId"
              :items="activeGroups"
              item-text="name"
              item-value="_id"
              label="Ryhmä"
              no-data-text="Ryhmän nimi tai *"
              outlined
              dense
              small-chips
              hide-details
              clearable
              no-filter
              @input.native="getGroups"
            >
            </v-autocomplete>
            <small>Ryhmän nimi tai *</small>
          </v-form>

          <v-card class="mt-1">
            <v-card-title>
              <p :class="!groupId ? 'error--text' : 'success--text'" class="mt-1">
                {{
                  !groupId
                    ? "Ryhmät poistetaan seuraavilta tileiltä, jos tallennat"
                    : "Ryhmä liitetään tileihin, jos tallennat"
                }}
              </p>
              <v-spacer></v-spacer>
            </v-card-title>

            <v-virtual-scroll
              :items="selectedAccounts"
              :item-height="25"
              height="250"
              background-color="transparent"
            >
              <template v-slot="{ item }">
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                  </v-list-item-content>

                  <v-list-item-action> </v-list-item-action>
                </v-list-item>
              </template>
            </v-virtual-scroll>
          </v-card>
        </v-card-text>

        <v-card-actions>
          <v-btn color="error" outlined @click="dialog = false">Poistu</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="info" @click="edit">Tallenna</v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import mixins from "@/mixins/mixins";
import { debounce } from "../../../utils/helpers";
import { mapState, mapActions } from "vuex";
import validations from "@/validations";
import globalValues from "../../../configs/globalValues";

export default {
  props: {
    value: { type: Boolean, default: false },
  },

  mixins: [mixins],

  data() {
    return {
      groupId: null,
      groupInput: "",
      validations,
      globalValues,
    };
  },

  computed: {
    ...mapState("group", ["activeGroups", "selectedAccounts"]),

    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  watch: {
    groupInput: debounce(function (newVal) {
      this.searchGroups(`/api/v1/group/search?search=${newVal}`);
    }, 1000),

    value(val) {
      if (!val) this.groupId = null;
    },
  },

  methods: {
    ...mapActions("group", ["searchGroups"]),
    ...mapActions("account", ["attachGroupToMultipleAccounts"]),

    getGroups(event) {
      this.groupInput = event.target.value;
    },

    async edit() {
      try {
        if (!this.groupId) {
          if (!confirm("Oletko aivan varma, että haluat poistaa valittujen tilien ryhmät?")) {
            return;
          }
        } else {
          if (!confirm("Oletko aivan varma, että haluat liittää tilit valittuun ryhmään?")) {
            return;
          }
        }

        const accountIds = this.selectedAccounts.map((el) => el._id);
        await this.attachGroupToMultipleAccounts({ groupId: this.groupId, accountIds });
        this.$emit("emptyselectedandfetch");
        this.dialog = false;
        if (this.groupId) {
          this.showPopup("Ryhmä liitetty tileihin", "success");
        } else {
          this.showPopup("Ryhmä poistettu tileiltä", "success");
        }
        this.dialog = false;
      } catch (err) {
        this.showPopup(err, "error");
      }
    },
  },
};
</script>

<style scoped></style>
